import { render, staticRenderFns } from "./ImageResource.vue?vue&type=template&id=430f5cee&scoped=true&"
import script from "./ImageResource.vue?vue&type=script&lang=js&"
export * from "./ImageResource.vue?vue&type=script&lang=js&"
import style0 from "./ImageResource.vue?vue&type=style&index=0&id=430f5cee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "430f5cee",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDialog,VIcon})
