<template>
	<div class="blockSession">
		<div :class="[sessionBlock.tag_style, { 'with-bg': haveBg }, 'block-no-action']">
			<LabelTag class="session-tag" v-if="sessionBlock.tag_display" :label="sessionBlock.tag_display" />
			<h2 v-if="sessionBlock.title" class="title">{{ sessionBlock.title }}</h2>
			<div v-if="sessionBlock.rich_text_content" v-html="sessionBlock.rich_text_content"></div>
		</div>
		<div class="mt-4" v-if="sessionBlock.support_resource_ids && sessionBlock.support_resource_ids.length !== 0">
			<SupportResources :supportResources="sessionBlock.support_resource_ids" />
		</div>
	</div>
</template>

<script>
import LabelTag from '@/components/other/LabelTag.vue';
import SupportResources from '@/components/supportResources/SupportResources.vue';

export default {
	name: 'BlockNoAction',
	components: { LabelTag, SupportResources },
	props: {
		sessionBlock: Object,
	},
	computed: {
		haveBg() {
			const tagNamesHaveBg = ['definition', 'rule'];
			return tagNamesHaveBg.includes(this.sessionBlock.tag_style);
		},
	},
};
</script>

<style lang="scss" scoped>
.block-no-action {
	display: flex;
	flex-direction: column;
	gap: 16px;
	line-height: 22px;

	.session-tag {
		width: fit-content;
	}
}
.with-bg {
	padding: 12px;
	border-radius: 8px;
}
</style>
