<template>
	<div class="audio-resource rounded-lg">
		<div class="audio-title">{{ resourceItem.name }}</div>
		<div class="essential_audio" id="essential-audio-player" :data-url="resourceItem.url" data-scratch></div>
	</div>
</template>

<script>
export default {
	name: 'AudioResource',
	props: {
		resourceItem: Object,
	},

	mounted() {
		this.initializeAudioPlayer();
	},
	methods: {
		initializeAudioPlayer() {
			if (window.Essential_Audio) {
				window.Essential_Audio.init();
			} else {
				console.error('Essential_Audio error');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.audio-resource {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 16px 26px 16px;
	gap: 8px;
	border: 1px solid #ddd;
	.audio-title {
		margin-bottom: 14px;
		font-weight: bold;
	}
}
</style>
