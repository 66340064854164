<template>
	<div
		class="session-position-header ortho-icon-grey text-center"
		:class="{ 'margin-bot': $vuetify.breakpoint.smAndDown }"
	>
		Session {{ session.position }}/{{ chapter.sessions.length }}
		<span v-if="$vuetify.breakpoint.mdAndUp">- {{ chapter.name }}</span>
	</div>
</template>

<script>
export default {
	name: 'SessionPosition',
	props: { session: Object, chapter: Object },
};
</script>

<style lang="scss" scoped>
.session-position-header {
	font-weight: bold;
}
.margin-bot {
	margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
}
</style>
