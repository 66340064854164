<template>
	<div class="target-result-bar">
		<v-alert v-if="progressStatus === TRACK_PROGRESS_CONST.case1" prominent color="#2A3B4D" dark icon="mdi-timer-sand">
			<b>
				Session en cours, il te reste {{ totalQuizBlock - numQuizBlockCompleted }} quiz et
				{{ totalReviewBlock - numReviewBlockCompleted }} révision{{
					totalReviewBlock - numReviewBlockCompleted > 1 ? 's' : ''
				}}
				pour valider ta session !
			</b>
		</v-alert>
		<v-alert
			v-else-if="progressStatus === TRACK_PROGRESS_CONST.case2"
			prominent
			color="#2A3B4D"
			dark
			icon="mdi-timer-sand"
		>
			<b>
				Session en cours, il te reste {{ totalReviewBlock - numReviewBlockCompleted }} révision{{
					totalReviewBlock - numReviewBlockCompleted > 1 ? 's' : ''
				}}
				pour valider ta session !
			</b>
		</v-alert>
		<v-alert
			v-else-if="progressStatus === TRACK_PROGRESS_CONST.case3"
			prominent
			color="#2A3B4D"
			dark
			icon="mdi-timer-sand"
		>
			<b>Session en cours, il te reste {{ totalQuizBlock - numQuizBlockCompleted }} quiz pour valider ta session !</b>
		</v-alert>
		<v-alert v-else-if="progressStatus === TRACK_PROGRESS_CONST.case4" prominent color="#2A3B4D" dark icon="mdi-target">
			<b>Obtiens 80% de bonnes réponses sur les quiz pour débloquer la session suivante ! </b>
		</v-alert>
		<v-alert v-else-if="passedThisSession == false" prominent text type="info" color="#4e52ba">
			<b>Meilleur score = {{ session.score }}%, tu peux mieux faire !</b>
		</v-alert>
		<v-alert v-else-if="passedThisSession == true" prominent type="success" color="#2dc7ae">
			<b>Bravo ! Exercice validé avec {{ session.score }}% de bonnes réponses</b>
		</v-alert>
	</div>
</template>

<script>
const PASSING_SCORE = 80;

export default {
	name: 'TargetResultBar',
	props: {
		session: Object,
	},
	data() {
		return {
			TRACK_PROGRESS_CONST: {
				case1: 'has-track-reponse-but-not-complete-any-quiz-or-review',
				case2: 'finished-all-quiz-blocks',
				case3: 'finished-all-review-blocks',
				case4: 'never-do-this-session',
			},
			totalQuizBlock: 0,
			totalReviewBlock: 0,
			numQuizBlockCompleted: 0,
			numReviewBlockCompleted: 0,
		};
	},
	computed: {
		passedThisSession() {
			return this.session.score && this.session.score >= PASSING_SCORE;
		},
		hasSessionDone() {
			return this.session.hasSessionDone;
		},
		progressStatus() {
			let hasTrackResponse = false;

			this.session.sessionBlocks.forEach((sessionBlock) => {
				let totalTrackOfBlock = 0;
				let countTrackHasReponse = 0;

				//count block type
				if (sessionBlock.type === 'quiz') this.totalQuizBlock += 1;
				else if (sessionBlock.type === 'review') this.totalReviewBlock += 1;

				if (sessionBlock.questionnaires == null) return; // Guard condition

				sessionBlock.questionnaires.forEach((questionnaire) => {
					if (questionnaire.tracks == null) return; // Guard condition
					questionnaire.tracks.forEach((track) => {
						totalTrackOfBlock += 1;
						if (track.trackResponse != null) {
							hasTrackResponse = true;
							countTrackHasReponse += 1;
						}
					});
				});

				if (totalTrackOfBlock !== 0 && countTrackHasReponse !== 0) {
					if (totalTrackOfBlock === countTrackHasReponse && sessionBlock.type === 'quiz')
						this.numQuizBlockCompleted += 1;
					else if (totalTrackOfBlock === countTrackHasReponse && sessionBlock.type === 'review')
						this.numReviewBlockCompleted += 1;
				}
			});

			if (this.totalQuizBlock === 0 && this.totalReviewBlock === 0) return 'session-has-no-quiz-no-review';

			if (hasTrackResponse == false) {
				return this.TRACK_PROGRESS_CONST.case4;
			} else if (
				this.numQuizBlockCompleted === this.totalQuizBlock &&
				this.numReviewBlockCompleted !== this.totalReviewBlock
			) {
				return this.TRACK_PROGRESS_CONST.case2;
			} else if (
				this.numReviewBlockCompleted === this.totalReviewBlock &&
				this.numQuizBlockCompleted !== this.totalQuizBlock
			) {
				return this.TRACK_PROGRESS_CONST.case3;
			} else if (
				hasTrackResponse &&
				this.numQuizBlockCompleted < this.totalQuizBlock &&
				this.numReviewBlockCompleted < this.totalReviewBlock
			) {
				return this.TRACK_PROGRESS_CONST.case1;
			}
			return '';
		},
	},
};
</script>

<style lang="scss" scoped>
.target-result-bar {
	.v-alert {
		margin-bottom: 0;
		border-radius: 8px;
	}
}
</style>
