<template>
	<div class="session-info-icons d-flex justify-center">
		<div class="item d-flex align-center">
			<img src="@/assets/icons/time.png" class="mr-2" alt="clock" />
			<div class="d-flex flex-column">
				<span class="label">Durée</span>
				<span class="value">{{ duration ? duration : '' }}</span>
			</div>
		</div>

		<div class="item d-flex align-center">
			<img src="@/assets/icons/difficulty.png" class="mr-2" alt="meter" />
			<div class="d-flex flex-column">
				<span class="label">Difficulté</span>
				<span class="value capitalize">{{ difficulty }}</span>
			</div>
		</div>

		<div class="item d-flex align-center">
			<img src="@/assets/icons/nb_learner.png" class="mr-2" alt="checkbox" />
			<div class="d-flex flex-column">
				<span class="label">Apprenants</span>
				<span v-if="nb_learner != null" class="value">{{ nb_learner }}</span>
				<span v-else class="value">1892</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SessionInfoIcons',
	props: {
		difficulty: String,
		duration: String,
		nb_learner: Number,
	},
};
</script>

<style lang="scss" scoped>
.session-info-icons {
	gap: 25px;

	.item {
		img {
			width: 40px;
			height: 40px;
		}
		.label {
			font-size: 12px;
		}
		.value {
			font-size: 14px;
			font-weight: bold;
		}
	}
}

@media screen and (max-width: 600px) {
	.session-info-icons {
		gap: 20px;

		.item {
			img {
				width: 30px;
				height: 30px;
			}
		}
	}
}
</style>
