<template>
	<div class="support-ressources">
		<div v-for="(resourceItem, index) in sortPositionAsc(supportResources)" :key="index">
			<VideoResource v-if="resourceItem.type === ResourceType.video" :resourceItem="resourceItem" />
			<ImageResource v-if="resourceItem.type === ResourceType.img" :resourceItem="resourceItem" />
			<DocResource v-if="resourceItem.type === ResourceType.doc" :resourceItem="resourceItem" />
			<AudioResource v-if="resourceItem.type === ResourceType.audio" :resourceItem="resourceItem" />
			<TextResource v-if="resourceItem.type === ResourceType.text" :resourceItem="resourceItem" />
		</div>
	</div>
</template>

<script>
import ResourceType from '@/constants/resourceType.js';

import VideoResource from '@/components/supportResources/VideoResource.vue';
import ImageResource from '@/components/supportResources/ImageResource.vue';
import DocResource from '@/components/supportResources/DocResource.vue';
import AudioResource from '@/components/supportResources/AudioResource.vue';
import TextResource from '@/components/supportResources/TextResource.vue';

export default {
	name: 'SupportResources',
	components: {
		VideoResource,
		ImageResource,
		DocResource,
		AudioResource,
		TextResource,
	},
	props: {
		supportResources: Array,
	},
	data() {
		return { ResourceType };
	},
	methods: {
		sortPositionAsc(supportResources) {
			return [...supportResources].sort((a, b) => a.position - b.position);
		},
	},
};
</script>

<style lang="scss" scoped>
.support-ressources {
	display: flex;
	flex-direction: column;
	gap: 16px;
}
</style>
