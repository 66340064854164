<template>
	<BlockWithActions
		v-if="sessionBlock.questionnaires != null && sessionBlock.questionnaires.length !== 0"
		:sessionBlock="sessionBlock"
		:formationProgress="formationProgress"
		:isDisplay="isBlockWithActionDisplay"
	/>
	<BlockNoAction v-else :sessionBlock="sessionBlock" />
</template>

<script>
import BlockNoAction from '@/components/session-block/BlockNoAction.vue';
import BlockWithActions from '@/components/session-block/BlockWithActions.vue';

export default {
	name: 'SessionBlock',
	components: { BlockNoAction, BlockWithActions },
	props: {
		sessionBlock: Object,
		formationProgress: Object,
		isBlockWithActionDisplay: Boolean,
	},
};
</script>

<style lang="scss" scoped></style>
