<template>
	<div
		:class="{
			[sessionBlock.tag_style]: true,
			'session-block-type-quiz': sessionBlock.type === 'quiz',
			'session-block-type-review': sessionBlock.type === 'review',
		}"
		class="block-type-quiz-and-review"
	>
		<div
			class="top rounded-t-lg pa-3"
			v-bind:class="[sessionBlock.type === 'quiz' ? 'bg-block-quiz-primary' : 'bg-block-review-primary']"
		>
			<div class="d-flex justify-space-between align-start">
				<LabelTag v-if="sessionBlock.tag_display" :label="sessionBlock.tag_display" class="mb10" />
				<SessionScore v-if="averageScore" :score="averageScore" />
			</div>

			<h2 v-if="sessionBlock.title" class="title">{{ sessionBlock.title }}</h2>
			<div v-if="sessionBlock.rich_text_content" v-html="sessionBlock.rich_text_content"></div>
		</div>

		<div
			class="bottom rounded-b-lg pa-3"
			v-bind:class="[sessionBlock.type === 'quiz' ? 'bg-block-quiz-secondary' : 'bg-block-review-secondary']"
		>
			<QuestionnaireCardItem
				v-for="questionnaire in sortQuestionnaireASC(sessionBlock.questionnaires)"
				:key="questionnaire._id"
				:questionnaire="questionnaire"
			/>
		</div>
	</div>
</template>

<script>
import LabelTag from '@/components/other/LabelTag.vue';
import QuestionnaireCardItem from '@/components/session-block/QuestionnaireCardItem.vue';
import SessionScore from '../score/session-score/SessionScore.vue';

export default {
	name: 'BlockTypeQuizAndReview',
	components: { LabelTag, QuestionnaireCardItem, SessionScore },
	props: { sessionBlock: Object, sessionBlockprogress: Object },
	computed: {
		allScoresPresent() {
			if (this.sessionBlockprogress == null || this.sessionBlockprogress.questionnaires == null) return;
			return this.sessionBlockprogress.questionnaires.every(
				(q) => q.questionnaireScore !== null && q.questionnaireScore !== undefined
			);
		},
		averageScore() {
			if (!this.allScoresPresent) {
				return null;
			}

			let totalScore = 0;
			let count = 0;

			this.sessionBlockprogress.questionnaires.forEach((questionnaire) => {
				totalScore += questionnaire.questionnaireScore;
				count++;
			});

			return count > 0 ? Math.ceil(totalScore / count).toString() : null;
		},
	},
	methods: {
		sortQuestionnaireASC(questionnaire) {
			return [...questionnaire].sort((a, b) => a.position - b.position);
		},
	},
};
</script>

<style lang="scss" scoped>
.bottom {
	display: flex;
	flex-direction: column;
	gap: 12px;
}
</style>
