<template>
	<div :class="{ [sessionBlock.tag_style]: true }" class="session-block-type-training">
		<div class="top rounded-t-lg pa-3 bg-block-training-primary">
			<div class="d-flex justify-space-between align-start">
				<LabelTag v-if="sessionBlock.tag_display" :label="sessionBlock.tag_display" class="mb10" />
			</div>

			<h2 v-if="sessionBlock.title" class="title">{{ sessionBlock.title }}</h2>
			<div v-if="sessionBlock.rich_text_content" v-html="sessionBlock.rich_text_content"></div>
		</div>

		<div class="bottom rounded-b-lg pa-3 bg-block-training-secondary">
			<div v-for="questionnaire in sessionBlock.questionnaires" :key="questionnaire._id">
				<div v-for="track in sortTracksASC(questionnaire.tracks)" :key="track._id">
					<TrackCardItem :track="track" class="track-item" :sessionBlockId="sessionBlock._id" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import LabelTag from '@/components/other/LabelTag.vue';
import TrackCardItem from '@/components/session-block/TrackCardItem.vue';

export default {
	name: 'BlockTypeTraining',
	components: { LabelTag, TrackCardItem },
	props: { sessionBlock: Object, sessionBlockprogress: Object },
	methods: {
		sortTracksASC(tracks) {
			return [...tracks].sort((a, b) => a.position - b.position);
		},
	},
};
</script>

<style lang="scss" scoped>
.session-block-type-training {
	.bottom {
		> div {
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
	}
}
</style>
