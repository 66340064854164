<template>
	<div ref="TextResource"></div>
</template>

<script>
export default {
	name: 'TextResource',
	props: {
		resourceItem: {
			type: Object,
			required: true,
		},
	},
	watch: {
		resourceItem: {
			handler() {
				this.updateContent();
			},
			immediate: true,
		},
	},
	methods: {
		updateContent() {
			if (this.$refs == null || this.$refs.TextResource == null) return;
			this.$refs.TextResource.innerHTML = ''; // Clear previous content
			const div = document.createElement('div');
			div.innerHTML = this.resourceItem.support_content;
			this.$refs.TextResource.appendChild(div);

			const scripts = div.querySelectorAll('script');
			const externalScripts = Array.from(scripts).filter((script) => script.src);
			const inlineScripts = Array.from(scripts).filter((script) => !script.src);

			this.loadScriptsSequentially(externalScripts)
				.then(() => {
					inlineScripts.forEach((script) => this.runInlineScript(script));
				})
				.catch((err) => {
					console.error('Failed to load scripts:', err);
				});
		},
		loadScriptsSequentially(scripts) {
			return scripts.reduce((promise, script) => {
				return promise.then(() => this.loadScript(script.src));
			}, Promise.resolve());
		},
		loadScript(src) {
			return new Promise((resolve, reject) => {
				const script = document.createElement('script');
				script.src = src;
				script.onload = resolve;
				script.onerror = reject;
				document.head.appendChild(script);
			});
		},
		runInlineScript(script) {
			const newScript = document.createElement('script');
			newScript.type = 'text/javascript';
			newScript.innerHTML = script.innerHTML;
			document.head.appendChild(newScript);
		},
	},
	mounted() {
		this.updateContent();
	},
};
</script>

<style lang="scss" scoped></style>
