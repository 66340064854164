<template>
	<div class="document-resource rounded-lg pa-4">
		<div class="document-title">{{ resourceItem.name }}</div>
		<div class="document-actions">
			<button @click="downloadDocument" class="action-button download-button" title="Télécharger">
				<v-icon size="18px">mdi-download</v-icon>
			</button>
			<a :href="resourceItem.url" target="_blank" class="action-button view-button" title="Voir">
				<v-icon size="18px">mdi-eye-outline</v-icon>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DocResource',
	props: {
		resourceItem: {
			type: Object,
			required: true,
		},
	},
	methods: {
		async downloadDocument() {
			try {
				const response = await fetch(this.resourceItem.url);

				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;

				// Extract the file name from the URL
				link.download = this.resourceItem.url.split('/').pop();
				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error('Download failed', error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.document-resource {
	gap: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	border: 1px solid #ddd;
	margin-bottom: 10px;
	.document-title {
		font-weight: bold;
	}
	.document-actions {
		display: flex;
		gap: 10px;
		.action-button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border: 1px solid #ddd;
			border-radius: 50%;
			background-color: #fff;
			color: #333;
			cursor: pointer;
			transition: background-color 0.3s ease;
			&:hover {
				background-color: #f0f0f0;
			}
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}
</style>
