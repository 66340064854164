<template>
	<div class="session-score" :class="[isSuccess ? 'ortho-icon-green' : 'ortho-icon-grey']">
		<span>{{ score }}%</span>
		<img v-if="isSuccess" src="@/assets/icons/correct-green.svg" />
		<img v-else src="@/assets/icons/cross.svg" />
	</div>
</template>

<script>
export default {
	name: 'SessionScore',
	props: {
		score: {
			type: String,
			required: true,
		},
	},
	computed: {
		isSuccess() {
			return this.score >= 80;
		},
	},
};
</script>

<style lang="scss" scoped>
.session-score {
	display: flex;
	align-items: center;
	gap: 4px;
	font-weight: bold;
	font-size: 14px;
	img {
		width: 25px;
	}
}
</style>
