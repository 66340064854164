<template>
	<div class="questionnaire-card-item d-flex py-2">
		<div class="flex-grow-1">
			<h3>{{ questionnaire.title }}</h3>
		</div>
		<div>
			<v-btn :to="buttonLink" dark class="black btn-action" @click="tracker()">
				<span class="white--text font-weight-bold">{{ buttonActionText }}</span>
			</v-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'QuestionnaireCardItem',
	props: { questionnaire: Object },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),

		// Determines the button text based on the quiz state
		buttonActionText() {
			if (this.trackScoresCount === 0) {
				return 'Commencer';
			} else if (this.trackScoresCount < this.sortedTracks.length) {
				return 'Continuer';
			} else {
				return 'Refaire';
			}
		},

		// Counts the number of tracks that have scores
		trackScoresCount() {
			const { sessionId } = this.$route.params;
			let count = 0;

			this.sortedTracks.forEach((track) => {
				const hasScore = this.formationProgress.sessionScoresHasTrackResponses.some((progress) =>
					progress.trackResponses.some(
						(response) =>
							response.session_block_id === this.questionnaire.session_block_id &&
							response.track_id === track._id &&
							response.session_id === sessionId
					)
				);
				if (hasScore) {
					count++;
				}
			});

			return count;
		},

		// Generates the button link to the next track without a score or the first track if all tracks have scores
		buttonLink() {
			const { sessionId, formationId } = this.$route.params;
			const nextTrack = this.getNextTrackWithoutScore() || this.sortedTracks[0];

			return {
				name: 'TrackPage',
				params: {
					sessionId,
					formationId,
					sessionBlockId: this.questionnaire.session_block_id,
					trackId: nextTrack._id,
				},
			};
		},

		// Sort tracks by their position
		sortedTracks() {
			return this.questionnaire.tracks.slice().sort((a, b) => a.position - b.position);
		},
	},

	methods: {
		// Finds the next track without a score
		getNextTrackWithoutScore() {
			const { sessionId } = this.$route.params;

			// Create a set of track_ids that have scores
			const trackWithScoreIds = new Set(
				this.formationProgress.sessionScoresHasTrackResponses.flatMap((progress) =>
					progress.trackResponses
						.filter(
							(response) =>
								response.session_id === sessionId && response.session_block_id === this.questionnaire.session_block_id
						)
						.map((response) => response.track_id)
				)
			);

			// Find the first track without a score
			return this.sortedTracks.find((track) => !trackWithScoreIds.has(track._id));
		},
		tracker() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-quiz-play');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.questionnaire-card-item {
	gap: 12px;
}
@media only screen and (max-width: 900px) {
	.questionnaire-card-item {
		flex-direction: column;
		.btn-action {
			width: 100%;
		}
	}
}

.v-btn:not(.v-btn--round) {
	min-width: 120px;
}
</style>
