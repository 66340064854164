<template>
	<div id="redaction-lesson">
		<div>
			<SessionPosition v-if="thisSession && thisSessionChapter" :session="thisSession" :chapter="thisSessionChapter" />
			<h1 class="text-center">{{ session.title }}</h1>
		</div>
		<SessionInfoIcons
			v-if="!isModal"
			:difficulty="session.difficulty"
			:duration="session.duration"
			:nb_learner="session.nb_learner"
		/>
		<TargetResultBar v-if="!isModal && thisSession" :session="thisSession" />
		<template v-for="sessionBlock in sortPositionASC(session.sessionBlocks)">
			<!-- blockSession -->
			<SessionBlock
				:key="sessionBlock._id"
				:sessionBlock="sessionBlock"
				:formationProgress="formationProgress"
				:isBlockWithActionDisplay="isBlockWithActionDisplay"
			/>
		</template>
	</div>
</template>

<script>
import SessionInfoIcons from '@/components/session-block/SessionInfoIcons.vue';
import SessionBlock from '@/components/session-block/SessionBlock.vue';
import SessionPosition from '@/components/session-block/SessionPosition.vue';
import TargetResultBar from '@/components/targetResultBar/TargetResultBar.vue';

export default {
	name: 'RedactionLesson',
	components: { SessionBlock, SessionInfoIcons, TargetResultBar, SessionPosition },
	props: {
		session: Object,
		isModal: {
			type: Boolean,
			default: false,
		},
		formationProgress: Object,
		isBlockWithActionDisplay: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			thisSession: null,
			thisSessionChapter: null,
		};
	},
	methods: {
		sortPositionASC() {
			return [...this.session.sessionBlocks].sort((a, b) => a.position - b.position);
		},
		setStateThisSession(id) {
			for (const parcoursItem of this.formationProgress.config.parcours_list) {
				const parcours = parcoursItem.parcours_id;
				for (const category of parcours.categories) {
					for (const session of category.sessions) {
						if (session._id === id) {
							this.thisSession = session;
							this.thisSessionChapter = category;
						}
					}
				}
			}
			return null;
		},
	},

	mounted() {
		const { sessionId } = this.$route.params;
		this.setStateThisSession(sessionId);
		window.scrollTo({ top: 0 });
	},
};
</script>

<style lang="scss">
#redaction-lesson {
	width: 100%;
	padding-top: 16px;
	padding-bottom: 70px;
	display: flex;
	flex-direction: column;
	gap: 40px;
}
@media only screen and (max-width: 900px) {
	#redaction-lesson {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@media (min-width: 1264px) {
	.container {
		max-width: 1485px;
	}
}
</style>
