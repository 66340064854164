<template>
	<v-chip label :color="color" class="labelTag" text-color="white">
		<b>{{ label }}</b>
	</v-chip>
</template>

<script>
export default {
	name: 'LabelTag',
	props: {
		label: String,
		color: String,
	},
};
</script>
