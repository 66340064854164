<template>
	<div class="image-ressource">
		<div v-if="resourceItem.support_content" v-html="resourceItem.support_content"></div>
		<div v-if="resourceItem.url">
			<img class="w-100 rounded-lg" :src="resourceItem.url" :title="resourceItem.name" />
			<v-icon @click="toggleFullscreen" class="fullscreen-icon">mdi-fullscreen</v-icon>
			<v-dialog v-model="overlay" max-width="800px">
				<v-card>
					<img class="w-100" :src="resourceItem.url" :title="resourceItem.name" />
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageResource',
	props: {
		resourceItem: Object,
	},
	data() {
		return {
			overlay: false,
		};
	},
	methods: {
		toggleFullscreen() {
			this.overlay = !this.overlay;
		},
	},
};
</script>

<style lang="scss" scoped>
.image-ressource {
	position: relative;

	.fullscreen-icon {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;
	}
}
</style>
