<template>
	<div class="track-card-item d-flex">
		<div class="pr-2 flex-grow-1">
			<h3>{{ `${track.position}. ${track.title}` }}</h3>
			<div v-if="track.sub_title">{{ track.sub_title }}</div>
		</div>
		<div class="d-flex align-center">
			<v-btn :to="buttonLink" dark class="black btn-action" @click="tracker()">
				<span class="white--text font-weight-bold">{{ buttonActionText }}</span>
			</v-btn>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'TrackCardItem',
	props: { track: Object, sessionBlockId: String },
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		buttonActionText() {
			if (this.isCompleted) {
				return 'Refaire';
			} else {
				return `S’entraîner`;
			}
		},
		isCompleted() {
			const { sessionId } = this.$route.params;
			return this.formationProgress.sessionScoresHasTrackResponses.some((progress) => {
				return progress.trackResponses.some((response) => {
					return (
						response.session_block_id === this.sessionBlockId &&
						response.track_id === this.track._id &&
						response.session_id === sessionId
					);
				});
			});
		},
		buttonLink() {
			const { sessionId, formationId } = this.$route.params;

			return {
				name: 'TrackPage',
				params: {
					sessionId,
					formationId,
					sessionBlockId: this.sessionBlockId,
					trackId: this.track._id,
				},
			};
		},
	},
	methods: {
		tracker() {
			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-training-play');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 900px) {
	.track-card-item {
		flex-direction: column;
		gap: 12px;
		.btn-action {
			width: 100%;
		}
	}
}
.v-btn:not(.v-btn--round) {
	min-width: 120px;
}
</style>
