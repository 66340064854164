<template>
	<div class="blockSession rounded-lg" v-if="isDisplay">
		<BlockTypeTraining
			v-if="sessionBlock.type === 'training'"
			:sessionBlock="sessionBlock"
			:sessionBlockprogress="sessionBlockprogress"
		/>
		<BlockTypeQuizAndReview
			v-if="['quiz', 'review'].includes(sessionBlock.type)"
			:sessionBlock="sessionBlock"
			:sessionBlockprogress="sessionBlockprogress"
		/>
	</div>
</template>

<script>
import BlockTypeTraining from '@/components/session-block/BlockTypeTraining.vue';
import BlockTypeQuizAndReview from '@/components/session-block/BlockTypeQuizAndReview.vue';

export default {
	name: 'BlockWithActions',
	components: { BlockTypeTraining, BlockTypeQuizAndReview },
	props: {
		sessionBlock: Object,
		formationProgress: Object,
		isDisplay: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		sessionBlockprogress() {
			return this.findFormationProgressSessionBlockById(this.sessionBlock._id);
		},
	},
	methods: {
		findFormationProgressSessionBlockById(id) {
			const { sessionId } = this.$route.params;
			for (const parcoursItem of this.formationProgress.config.parcours_list) {
				const parcours = parcoursItem.parcours_id;
				for (const category of parcours.categories) {
					for (const session of category.sessions) {
						if (session._id === sessionId) {
							for (const sessionBlock of session.sessionBlocks) {
								if (sessionBlock._id === id) {
									return sessionBlock;
								}
							}
						}
					}
				}
			}
			return null;
		},
	},
};
</script>

<style lang="scss" scoped></style>
